<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            header-component
            v-content
                v-snackbar(
                :timeout="3000",
                :top="true"
                :right="true"
                :color="planColor"
                v-model="planMsj")
                    | {{ planText }}
                    v-btn(flat, dark, @click.native="planMsj = false") {{$t('cerrar')}}
                v-container.grid-list-xl(fluid)
                    h1 {{$t('Planes')}}
                    div(v-if="$root.profile.billing.length")
                        h3 {{$t('Tus planes activos')}}
                        v-list
                            v-list-group(v-for="(tx, i) in $root.profile.billing" :key="'tx'+i")
                                v-list-tile(slot="activator")
                                    v-list-tile-title {{ tx.base*tx.quantity }} {{ $t((tx.base*tx.quantity) == 1 ? 'red' : 'redes') }} {{$t('por mes')}}
                                    //-, Vence en {{tx.expiration}}
                                v-list-tile(v-for="(n, i) in tx.networks" :key="'n'+i")
                                    v-list-tile-title 
                                        small {{$t('Red')}}: {{ n.name }}
                    div(v-if="$root.profile.pending.length")
                        h3 {{$t('Tienes transacciones pendientes por confirmar')}}
                        v-list
                            v-list-tile(v-for="(tx, i) in $root.profile.pending" :key="'tx2'+i")
                                | {{ tx.base*tx.quantity }} {{ $t((tx.base*tx.quantity) == 1 ? 'red' : 'redes') }} {{$t('por mes')}}
                                v-btn.primary(depressed small @click="cancel(tx.uuid)") {{$t('Cancelar')}}
                    h3 {{$t('Elegir plan')}}
                    v-list
                        v-list-tile
                            | {{ networkTotal }} {{ $t(networkTotal == 1 ? 'red' : 'redes') }} {{$t('por mes')}}
                    div
                        v-text-field(:label="$t('Ingresa el número de redes')" v-model="quantity" type="number" min="1")
                        v-btn.primary(depressed small @click="add") {{$t('Agregar')}}
                        v-btn.primary(depressed small @click="remove") {{$t('Quitar')}}
                        v-btn.primary(depressed small @click="acquire" :loading="loadingBtn") {{$t('Continuar')}}
                    div.mt-4(v-if="$root.profile.expired.length")
                        h3 {{$t('Planes expirados')}}
                        v-list
                            v-list-tile(v-for="(tx, i) in $root.profile.expired" :key="'tx3'+i")
                                | {{ tx.base*tx.quantity }} {{ $t((tx.base*tx.quantity) == 1 ? 'red' : 'redes') }} {{$t('por mes, expirado en')}} {{tx.datemodified}}
                                v-btn.primary(small depressed @click="renew(tx.uuid)") {{ $t('Renovar')}}
                    
</template>

<script>

    import auth from 'mixins/auth'
    import config from 'src/config'
    
    export default {
        mixins: [auth], 
        metaInfo() {
            return {
                title: this.$t('Planes')
            }
        },
        data() {
            return {
                quantity: 1,
                base: 1,
                priceBase: 10,
                loadingBtn: false,
                planMsj  : false,
                planColor: '',
                planText : '',
            }
        },
        watch: {
            quantity(){
                if(this.quantity < 1 || isNaN(this.quantity)){
                    this.quantity = 1
                }
            }
        },
        computed:{
            networkTotal(){
                return this.quantity * this.base
            },
            priceTotal(){
                return this.quantity * this.priceBase
            }
        },
        methods: {

            add(){
                return this.quantity++
            },

            remove(){
                return this.quantity <= 1 ? 1 : this.quantity--
            },

            acquire(){

                let post    = {
                    quantity: this.quantity
                }

                this.loadingBtn = true
                this.$api(this, (xhr) => {
                    xhr.post('/billing/plan', this.$qs.stringify(post)).then((r) => {

                        let data = r.data
                        this.loadingBtn = false
                        this.planMsj = true

                        if(data.response){
                            this.planColor = 'success'
                            this.planText = data.message
                            this.planModal = false
                            this.$router.go()
                        }else{
                            this.planColor = 'error'
                            this.planText = data.message
                        }
                    }).catch(() => this.loadingBtn = false)
                })
            },

            renew(billingid){

                if(!this.issubadmin){
                    return
                }

                let post = {
                    billingid
                }

                this.$api(this, (xhr) => {
                    xhr.post('/billing/renew', this.$qs.stringify(post)).then((r) => {

                        let data = r.data
                        this.planMsj = true

                        if(data.response){
                            this.planColor = 'success'
                            this.planText = data.message
                            this.$router.go()
                        }else{
                            this.planColor = 'error'
                            this.planText = data.message
                        }
                    }).catch(() => {})
                })
            },

            cancel(billingid){

                if(!this.issubadmin){
                    return
                }

                let post = {
                    billingid
                }

                this.$api(this, (xhr) => {
                    xhr.post('/billing/cancel', this.$qs.stringify(post)).then((r) => {

                        let data = r.data
                        this.planMsj = true

                        if(data.response){
                            this.planColor = 'success'
                            this.planText = data.message
                            this.$router.go()
                        }else{
                            this.planColor = 'error'
                            this.planText = data.message
                        }
                    }).catch(() => {})
                })
            }
        }
    }
    
</script>